import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import store from './redux/store'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import * as Sentry from '@sentry/react'

import './utils/proxy'
import './index.css'

Sentry.init({
  dsn: "https://11345a28eedc46b6aff31a8b604d717e@sen.idevbase.com/2",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

window.sentry = Sentry

const IndexLayout = React.lazy(() => import('./layouts/Index'))
const MonitorLayout = React.lazy(() => import('./layouts/Monitor'))
const GuideLayout = React.lazy(() => import('./layouts/Guide'))

const history = createBrowserHistory()

class SuspenseFallback extends React.Component {
  componentDidMount() {
    document.querySelector('.loader-container').classList.remove('load-spinner-close')
  }

  componentWillUnmount() {
    document.querySelector('.loader-container').classList.add('load-spinner-close')
  }

  render() {
    return null
  }
}

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zh_CN}>
      <Router history={history}>
        <React.Suspense fallback={<SuspenseFallback />}>
          <Switch>
            <Route path='/monitor' render={props => <MonitorLayout {...props} />} />
            <Route path='/guide' render={props => <GuideLayout {...props} />} />
            <Route path='/' render={props => <IndexLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
)
