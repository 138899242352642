import { CERTIFICATE_AUTH_MODE, SELECT_OPTION_ALL } from "../../utils/constant"

const GET_CERTIFICATES_ING = 'CERTIFICATE/GET_CERTIFICATES_ING'
const GET_CERTIFICATES_SUCCESS = 'CERTIFICATE/GET_CERTIFICATES_SUCCESS'
const GET_CERTIFICATES_FAILURE = 'CERTIFICATE/GET_CERTIFICATES_FAILURE'

const GET_CERTIFICATE_DETAIL_ING = 'CERTIFICATE/GET_CERTIFICATE_DETAIL_ING'
const GET_CERTIFICATE_DETAIL_SUCCESS = 'CERTIFICATE/GET_CERTIFICATE_DETAIL_SUCCESS'
const GET_CERTIFICATE_DETAIL_FAILURE = 'CERTIFICATE/GET_CERTIFICATE_DETAIL_FAILURE'

const CREATE_CERTIFICATE_ING = 'CERTIFICATE/CREATE_CERTIFICATE_ING'
const CREATE_CERTIFICATE_SUCCESS = 'CERTIFICATE/CREATE_CERTIFICATE_SUCCESS'
const CREATE_CERTIFICATE_FAILURE = 'CERTIFICATE/CREATE_CERTIFICATE_FAILURE'

const CREATE_CERTIFICATE_ORDER_ING = 'CERTIFICATE/CREATE_CERTIFICATE_ORDER_ING'
const CREATE_CERTIFICATE_ORDER_SUCCESS = 'CERTIFICATE/CREATE_CERTIFICATE_ORDER_SUCCESS'
const CREATE_CERTIFICATE_ORDER_FAILURE = 'CERTIFICATE/CREATE_CERTIFICATE_ORDER_FAILURE'

const REMOVE_CERTIFICATE_ING = 'CERTIFICATE/REMOVE_CERTIFICATE_ING'
const REMOVE_CERTIFICATE_SUCCESS = 'CERTIFICATE/REMOVE_CERTIFICATE_SUCCESS'
const REMOVE_CERTIFICATE_FAILURE = 'CERTIFICATE/REMOVE_CERTIFICATE_FAILURE'

const CHANGE_CERTIFICATE_ALIAS_ING = 'CERTIFICATE/CHANGE_CERTIFICATE_ALIAS_ING'
const CHANGE_CERTIFICATE_ALIAS_SUCCESS = 'CERTIFICATE/CHANGE_CERTIFICATE_ALIAS_SUCCESS'
const CHANGE_CERTIFICATE_ALIAS_FAILURE = 'CERTIFICATE/CHANGE_CERTIFICATE_ALIAS_FAILURE'

const CHANGE_CERTIFICATE_CONFIG_ING = 'CERTIFICATE/CHANGE_CERTIFICATE_CONFIG_ING'
const CHANGE_CERTIFICATE_CONFIG_SUCCESS = 'CERTIFICATE/CHANGE_CERTIFICATE_CONFIG_SUCCESS'
const CHANGE_CERTIFICATE_CONFIG_FAILURE = 'CERTIFICATE/CHANGE_CERTIFICATE_CONFIG_FAILURE'

const defaultState = {
  certificates: {
    count: 0,
    rows: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CERTIFICATES_SUCCESS:
      return {
        ...state,
        certificates: action.payload
      }
    case GET_CERTIFICATES_FAILURE:
      return {
        ...state,
        certificates: {
          count: 0,
          rows: []
        }
      }
    case GET_CERTIFICATE_DETAIL_SUCCESS:
      return {
        ...state,
        certificateDetail: action.payload
      }
    case GET_CERTIFICATE_DETAIL_FAILURE:
      return {
        ...state,
        certificateDetail: null
      }
    case CREATE_CERTIFICATE_ORDER_SUCCESS:
      return {
        ...state,
        certificateThirdOrder: action.payload
      }
    case CREATE_CERTIFICATE_ORDER_ING:
    case CREATE_CERTIFICATE_ORDER_FAILURE:
      return {
        ...state,
        certificateThirdOrder: null
      }
    default:
      return { ...state }
  }
}

export const getCertificates = ({ searchContent, searchCertBrand, searchCertType, offset, limit, sortKey, sortOrder }) => {
  searchCertBrand = searchCertBrand === SELECT_OPTION_ALL ? '' : searchCertBrand
  searchCertType = searchCertType === SELECT_OPTION_ALL ? '' : searchCertType
  return {
    types: [GET_CERTIFICATES_ING, GET_CERTIFICATES_SUCCESS, GET_CERTIFICATES_FAILURE],
    promise: (apiClient) => apiClient.get('/api/certificates', { params: { searchContent, searchCertBrand, searchCertType, offset, limit, sortKey, sortOrder } }),
    offset,
    limit
  }
}

export const getCertificateDetail = ({ certificateId }) => {
  return {
    types: [GET_CERTIFICATE_DETAIL_ING, GET_CERTIFICATE_DETAIL_SUCCESS, GET_CERTIFICATE_DETAIL_FAILURE],
    promise: (apiClient) => apiClient.get(`/api/certificates/${certificateId}`),
    certificateId
  }
}

export const createCertificate = ({ type, brand, authMode, domains, thirdOrderId, dnsServerId, dnsProviderId, dnsServerAccessJson }) => {
  const body = { type, authMode, domains, brand, thirdOrderId }
  if (authMode === CERTIFICATE_AUTH_MODE.DNS_API_AUTH_MODE) {
    if (dnsServerId) {
      body.dnsServerId = dnsServerId
    } else {
      body.dnsProviderId = dnsProviderId
      body.dnsServerAccessJson = dnsServerAccessJson
    }
  }

  return {
    types: [CREATE_CERTIFICATE_ING, CREATE_CERTIFICATE_SUCCESS, CREATE_CERTIFICATE_FAILURE],
    promise: (apiClient) => apiClient.post('/api/certificates', body)
  }
}

export const createCertificateOrder = ({ type, brand, domains, authMode }) => {
  const body = { type, brand, domains, authMode }
  return {
    types: [CREATE_CERTIFICATE_ORDER_ING, CREATE_CERTIFICATE_ORDER_SUCCESS, CREATE_CERTIFICATE_ORDER_FAILURE],
    promise: (apiClient) => apiClient.post('/api/certificates/order', body)
  }
}

export const changeCertificateAlias = (certificateId, alias) => {
  return {
    types: [CHANGE_CERTIFICATE_ALIAS_ING, CHANGE_CERTIFICATE_ALIAS_SUCCESS, CHANGE_CERTIFICATE_ALIAS_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/certificates/${certificateId}/alias`, {alias}),
    certificateId
  }
}

export const removeCertificate= ({certificateId}) => {
  return {
    types: [REMOVE_CERTIFICATE_ING, REMOVE_CERTIFICATE_SUCCESS, REMOVE_CERTIFICATE_FAILURE],
    promise: (apiClient) => apiClient.delete(`/api/certificates/${certificateId}`)
  }
}


export const changeCertificateConfig = ({certificateId, autoUpdate, autoPush, autoNotify, autoUpdateDaysInAdvance, autoNotifyDaysInAdvance, cloudServerIds}) => {
  return {
    types: [CHANGE_CERTIFICATE_CONFIG_ING, CHANGE_CERTIFICATE_CONFIG_SUCCESS, CHANGE_CERTIFICATE_CONFIG_FAILURE],
    promise: (apiClient) => apiClient.put(`/api/certificates/${certificateId}/config`, {autoUpdate, autoPush, autoNotify, autoUpdateDaysInAdvance, autoNotifyDaysInAdvance, cloudServerIds}),
    certificateId
  }
}
